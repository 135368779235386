<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-title>
          <span class="headline">
            Usuarios (Ojo: Afecta a también qdb!)
          </span>
        </v-card-title>
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="3"
                sm="2"
              >
                <v-text-field
                  dense
                  :disabled="!editMode || !item.isNew"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=2
                  v-model="item.userId"
                  :rules="userIdRules"
                  label="Id"
                ></v-text-field>
              </v-col>
              <v-col
                cols="9"
                sm="5"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.userName"
                  :rules="userNameRules"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="5"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.userEmail"
                  :rules="userEmailRules"
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="5"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.userPhone"
                  label="Teléfono"
                ></v-text-field>
              </v-col>
              <v-col
                cols="3"
                sm="2"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.twoFactor"
                  label="2FA"
                  :items="[{text: 'Activado', value: 1}, {text: 'Desactivado', value: 0}]"
                >
                </v-select>
              </v-col>
              <v-col
                cols="9"
                sm="5"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=255
                  v-model="item.pwdHash"
                  label="PwdHash (para QDB antiguo)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card
              flat
            >
              <v-card-text>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                  >
                    <p
                    class="text-h6"
                    >Derechos</p>
                  </v-col>
                  <v-col
                    v-for="(right, index) of rightsSystem"
                    :key="index"
                    cols="6"
                    sm="3"
                  >
                    <v-checkbox
                      class="mt-0"
                      :disabled="!editMode || (right.value === 1024 && item.userId === ownUserId)"
                      v-model="rightsArr"
                      :label="right.text"
                      :value="right.value"
                      :title="'Alias: ' + right.alias"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="editMode && !item.isNew && item.userId !== ownUserId"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
          >
            Volver
          </v-btn>
          <v-btn
            v-if="!editMode"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import Check from '@/utils/Check'
import ItemMixins from '@/utils/ItemMixins'

export default {
  mixins: [ItemMixins],
  beforeCreate () {
    this.Check = Check
  },
  data () {
    return {
      itemsName: 'users', // needed for ItemMixins
      itemName: 'user', // needed for ItemMixins
      userIdRules: [
        (v) => !!v || '¡Información requerida',
        (v) => (v && v.length <= 2) || 'Longitud máxima 2 caracteres'
      ],
      userNameRules: [
        (v) => !!v || '¡Información requerida',
        (v) => (v && v.length <= 30) || 'Longitud máxima 30 caracteres'
      ],
      userEmailRules: [
        (v) => !!v || '¡Información requerida',
        (v) => Check.isEmail(v) || 'No es un email válido',
        (v) => (v && v.length <= 30) || 'Longitud máxima 30 caracteres'
      ],
      rightsArr: [],
      rightsSystem: [
        { value: 1, alias: 'CAN_READ', text: 'Ver clientes y ventas, registros propios' },
        { value: 4, alias: 'CAN_WRITE', text: 'Modificar clientes y ventas, registros propios' }, // 4 !!!
        { value: 2, alias: 'CAN_READALL', text: 'Ver clientes y ventas, todos registros' }, // 2 !!!
        { value: 8, alias: 'CAN_WRITEALL', text: 'Modificar clientes y ventas, todos registros' },
        { value: 16, alias: 'CAN_PICK', text: 'Crear y gestionar pickings' },
        { value: 32, alias: 'CAN_SHIP', text: 'Crear y gestionar envíos' },
        { value: 64, alias: 'CAN_INVOICE', text: 'Crear y gestionar facturas' },
        { value: 128, alias: 'CAN_PURCHASES_READ', text: 'Ver proveedores y compras y acceso a margenes de ventas' },
        { value: 256, alias: 'CAN_PURCHASES_WRITE', text: 'Modificar proveedores, productos y compras' },
        { value: 512, alias: 'CAN_MANAGER', text: 'Acceso a reports' },
        { value: 1024, alias: 'CAN_ADMIN', text: 'Administrador de sistema' }
      ]
    }
  },
  computed: {
    ownUserId () {
      return this.$store.state.user.userId
    }
  },
  watch: {
    rightsArr: {
      handler (val) {
        // console.log(val)
        let rights = 0
        for (const el of val) {
          rights += el
        }
        if (this.item.rights !== rights) {
          this.item.rights = rights
        }
      }
    },
    item: {
      handler (val) {
        // set rightsArr according item.rights
        const arr = []
        for (const right of this.rightsSystem) {
          // system based on bitwise comparison
          // http://stackoverflow.com/questions/3673768/best-way-to-store-user-permissions
          if (val.rights & right.value) {
            arr.push(right.value)
          }
        }
        this.rightsArr = arr
        // console.log(this.rightsArr)
      }
    }
  },
  methods: {
    createItem () {
      this.item = {
        // workarround for datasets where the id is userdefined
        isNew: true,
        userId: '',
        userName: '',
        userEmail: '',
        userPhone: '',
        pwdHash: '',
        twoFactor: 0,
        rights: 0
      }
      this.editMode = true
    }
  }
}
</script>
