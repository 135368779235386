<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-title>
          <span class="headline">
            Configuración del sistema
          </span>
        </v-card-title>
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
          >
            <v-row
            >
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  dense
                  auto-select-first
                  persistent-placeholder
                  filled
                  v-model="sectionId"
                  :items="systemSections"
                  label="Sección"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                mt
                cols="12"
                sm="12"
                class="my-4"
              >
                <SettingsSystemSectionTable
                  v-model="sectionData"
                  :sectionId="sectionId"
                  :editMode="editMode"
                >
                </SettingsSystemSectionTable>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
            title="Cancelar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-else-if="sectionId !== ''"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import api from '../api'
import SettingsSystemSectionTable from '@/components/SettingsSystemSectionTable'
import EventBus from '@/components/EventBus'

export default {
  components: {
    SettingsSystemSectionTable
  },
  data () {
    return {
      valid: false,
      editMode: false,
      loading: true,
      systemSections: [],
      sectionId: '',
      sectionData: []
    }
  },
  mounted () {
    this.getSystemSections()
    EventBus.$on('ctrlKey_s', (e) => {
      console.log('ctrlKey_s')
      e.preventDefault()
      if (this.sectionId && this.editMode) {
        this.save()
      }
    })
    // esc => unselect item
    EventBus.$on('keyEsc', (e) => {
      if (this.editMode) {
        this.cancel()
      } else {
        // this.close()
      }
    })
    EventBus.$on('keyF2', (e) => {
      e.preventDefault()
      this.editMode = true
    })
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_s')
    EventBus.$off('keyEsc')
    EventBus.$off('keyF2')
  },
  watch: {
    sectionId (sectionId) {
      this.getItems(sectionId)
    }
  },

  methods: {
    getSystemSections () {
      this.loading = true
      api.getItems('system')
        .then(data => {
          this.loading = false
          this.systemSections = data
        })
        .catch(e => {
          this.loading = false
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    getItems (sectionId) {
      console.log('fetch')
      this.loading = true
      api.getItem('system', sectionId)
        .then(data => {
          this.loading = false
          this.sectionData = data
        })
        .catch(e => {
          this.loading = false
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    save () {
      if (!this.$refs.form.validate()) {
        return
      }
      // api.updateSystemSection(this.sectionId, this.sectionData)
      api.updateItem('system', this.sectionId, this.sectionData)
        .then(data => {
          this.sectionData = data
          this.editMode = false
          EventBus.$emit('dispatchInitApp')
          this.$store.commit('alert',
            {
              show: true,
              text: 'Registros actualizados',
              type: 'success'
            }
          )
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: e.message,
              type: 'error'
            }
          )
        })
    },
    cancel () {
      this.editMode = false
      // reset sectionData with old system data (just in case we edited and then canceled)
      this.getItems(this.sectionId)
    }
  }
}
</script>
