<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="2"
                sm="3"
              >
                <v-text-field
                  dense
                  disabled
                  persistent-placeholder
                  filled
                  type="text"
                  v-model="item.paymentTermId"
                  label="Id"
                ></v-text-field>
              </v-col>
            <v-col
              cols="10"
              sm="9"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                ref="focussedInput"
                type="text"
                maxlength=35
                v-model="item.paymentTerm"
                :rules="requiredRules"
                label="Texto"
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="6"
                sm="3"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.paymentTermDays"
                  :rules="positiveIntegerRules"
                  label="Días pago"
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="3"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.clientBank"
                  label="Mostrar Cuenta Cliente"
                  :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="3"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.quercusBank"
                  label="Mostrar Cuenta Quercus"
                  :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="3"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.deleted"
                  label="Esconder"
                  :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
        >
          <v-btn
            v-if="editMode && item.paymentTermId !== 'new_' && userCanEdit"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
            title="Cancelar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode && userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
            title="Volver a lista (Esc)"
          >
            Volver
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import ItemMixins from '@/utils/ItemMixins'

export default {
  mixins: [ItemMixins],
  data () {
    return {
      editRight: 'CAN_ADMIN',
      itemsName: 'paymentTerms',
      itemName: 'paymentTerm',
      requiredRules: [
        (v) => !!v || '¡Información requerida'
      ],
      positiveIntegerRules: [
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Se requiere un número (integro)'
      ]

    }
  },
  methods: {
    createItem () {
      this.item = {
        paymentTermId: 'new_',
        paymentTerm: '',
        paymentTermDays: 0,
        clientBank: 0,
        quercusBank: 0,
        deleted: 0
      }
      this.editMode = true
      this.modelItemId = 'new_'
    }
  }
}
</script>
