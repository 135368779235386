<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-card
      flat
    >
      <v-card-title>
        <span class="headline">
          Usuarios (Ojo: Afecta a también qdb!)
        </span>
      </v-card-title>
      <v-card-text
        class="pb-0"
      >
        <v-container
          fluid
        >
          <v-row
            class="overflowLayout"
          >
            <v-col
              class="overflowFlex"
            >
              <v-data-table
                style="white-space: nowrap;"
                dense
                :headers="headers"
                :items="items"
                :loading="loading"
                :options.sync="options"
                :footer-props="footerProps"
                :server-items-length="totalItems"
                @update:options="getItems()"
                fixed-header
                mobile-breakpoint="0"
                @click:row="showItem"
              >
                <template
                  v-slot:item="{ item, index }"
                >
                  <tr
                    :key="item.userId"
                    @click.stop="showItem(item)"
                    :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
                  >
                    <td>{{ item.userId }}</td>
                    <td> {{ item.userName }} </td>
                    <td> {{ item.userEmail }} </td>
                    <td> {{ item.userPhone }} </td>
                    <td> {{ item.twoFactor ? 'Activado' : 'Desactivado' }} </td>
                    <td class="text-right"> {{ item.rights }} </td>

                  </tr>
                </template>
                <template v-slot:[`footer.prepend`]>
                  <v-btn
                    @click="createItem ()"
                    title="Crear usuario (Ctrl. + Ins.)"
                    small
                    fab
                    color="primary"
                    elevation="2"
                  >
                    <v-icon>{{ mdiPlus }}</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'

export default {
  mixins: [ItemsMixins],
  data () {
    return {
      itemsName: 'users',
      itemName: 'user',
      headers: [
        {
          text: 'Id',
          value: 'userId',
          width: 100
        },

        {
          text: 'Nombre',
          value: 'userName'
        },
        {
          text: 'Email',
          value: 'userEmail'
        },
        {
          text: 'Teléfono',
          value: 'userPhone'
        },
        {
          text: '2FA',
          value: 'twoFactor'
        },
        {
          text: 'Derechos',
          value: 'rights'
        }
      ]
    }
  },
  methods: {
    defaultOptions () {
      return {
        sortBy: ['userId'], sortDesc: [false], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    }
  }
}
</script>
