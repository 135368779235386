<template>
  <v-container
    fluid
  >
    <v-row
      class="overflowLayout"
    >
      <v-col
        class="overflowFlex"
      >
        <v-data-table
          style="white-space: nowrap;"
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
          @update:options="updateQuery()"
          fixed-header
          mobile-breakpoint="0"
          height="calc(100vh - 130px)"
          @click:row="showItem"
          :value="selected"
          single-select
        >
          <template v-slot:[`body.prepend`]="{ headers }">
            <tr
              class="tableFilter"
              py-3
            >
              <td
                v-for="(header,key) in headers"
                :key="key"
                :class="header.class ? 'pb-1 ' + header.class : 'pb-1'"
              >
                <v-text-field
                  v-if="header.filterType === 'text'"
                  v-model="header.filterValue"
                  hide-details
                  single-line
                  dense
                  clearable
                  @keyup.esc="header.filterValue = ''"
                ></v-text-field>
                <v-select
                  v-else-if="header.filterType === 'select'"
                  flat
                  hide-details
                  dense
                  v-model="header.filterValue"
                  :items="header.selectList"
                ></v-select>
              </td>
            </tr>
          </template>
          <template
            v-slot:item="{ item, index }"
          >
            <tr
              :key="item.paymentTermId"
              @click.stop="showItem(item)"
              :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
            >
              <td class="text-right">{{ item.paymentTermId }}</td>
              <td>{{ item.paymentTerm }}</td>
              <td class="text-right">{{ item.paymentTermDays }}</td>
              <td>{{ item.clientBank ? 'yes' : 'no' }}</td>
              <td>{{ item.quercusBank ? 'yes' : 'no'  }}</td>
              <td>{{ item.deleted ? 'yes' : 'no'  }}</td>
            </tr>
          </template>
          <template v-slot:[`footer.prepend`]
          >
            <v-btn
              v-if="userCanEdit"
              @click="createItem ()"
              title="Crear forma de pago (Ctrl. + Ins.)"
              small
              fab
              color="primary"
              elevation="2"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'

export default {
  mixins: [ItemsMixins],
  data () {
    return {
      editRight: 'CAN_ADMIN',
      itemsName: 'paymentTerms',
      itemName: 'paymentTerm',
      headers: [
        {
          text: 'Id',
          align: 'right',
          value: 'paymentTermId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number',
          width: 100
        },
        {
          text: 'Texto',
          value: 'paymentTerm',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Días pago',
          value: 'paymentTermDays',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Cuenta Cliente',
          value: 'clientBank',
          filterType: 'select',
          filterValue: '',
          selectList: [
            { text: 'All', value: '' }, { text: 'no', value: '0' }, { text: 'yes', value: '1' }
          ],
          filterSearch: 'full'
        },
        {
          text: 'Cuenta Quercus',
          value: 'quercusBank',
          filterType: 'select',
          filterValue: '',
          selectList: [
            { text: 'All', value: '' }, { text: 'no', value: '0' }, { text: 'yes', value: '1' }
          ],
          filterSearch: 'full'
        },
        {
          text: 'Esconder',
          value: 'deleted',
          filterType: 'select',
          filterValue: '',
          selectList: [
            { text: 'All', value: '' }, { text: 'no', value: '0' }, { text: 'yes', value: '1' }
          ],
          filterSearch: 'full'
        }
      ]
    }
  }
}
</script>
