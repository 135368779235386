<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="2"
                sm="3"
              >
                <v-text-field
                  dense
                  disabled
                  persistent-placeholder
                  filled
                  type="text"
                  v-model="item.forwarderId"
                  label="Id"
                ></v-text-field>
              </v-col>
            <v-col
              cols="10"
              sm="9"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                ref="focussedInput"
                type="text"
                maxlength=30
                v-model="item.forwarderName"
                :rules="requiredRules"
                label="Nombre"
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row
              dense
            >
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=255
                v-model="item.forwarderHint"
                label="Comentarios"
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=12
                  v-model="item.forwarderIntegration"
                  label="Id Integración"
                ></v-text-field>
              </v-col>

              <v-col
                cols="4"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.forwarderPickupLink"
                  label="Mostrar enlace pickup"
                  :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                >
                </v-select>
              </v-col>
              <v-col
                cols="4"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.deleted"
                  label="Esconder"
                  :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.forwarderConfig"
                  auto-grow
                  label="forwarderConfig (JSON)"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.forwarderPickingText"
                  auto-grow
                  label="Texto en picking"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.forwarderClientText"
                  rows="2"
                  label="Texto en docs cliente"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
        >
          <v-btn
            v-if="editMode && item.forwarderId !== 'new_' && userCanEdit"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
            title="Cancelar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode && userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
            title="Volver a lista (Esc)"
          >
            Volver
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import ItemMixins from '@/utils/ItemMixins'

export default {
  mixins: [ItemMixins],
  data () {
    return {
      editRight: 'CAN_ADMIN',
      itemsName: 'forwarders',
      itemName: 'forwarder',
      requiredRules: [
        (v) => !!v || '¡Información requerida'
      ]

    }
  },
  methods: {
    createItem () {
      this.item = {
        forwarderId: 'new_',
        forwarderName: '',
        forwarderHint: '',
        forwarderIntegration: '',
        forwarderConfig: '',
        forwarderPickupLink: 0,
        forwarderPickingText: '',
        forwarderClientText: '',
        deleted: 0
      }
      this.editMode = true
      this.modelItemId = 'new_'
    }
  }
}
</script>
