<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="sectionItems"
    :options="{itemsPerPage: -1}"
    class="elevation-0"
    mobile-breakpoint="0"
  >

    <template
      v-slot:item="{ item, index }"
    >
      <tr
        :key="index"
      >
        <td>
          <v-btn
            :disabled="sectionItems.length === 1 || !editMode"
            @click="deleteRow(item.key)"
            title="Borrar registro"
            x-small
            fab
            class="elevation-0"

          >
            <v-icon
              color="error"
            >
              {{ mdiDelete }}
            </v-icon>
          </v-btn>
        </td>

        <td
          class="hideInputMsg pt-1"
        >
          <v-text-field
            :ref="item.key"
            dense
            :readonly="!editMode"
            solo
            flat
            type="text"
            maxlength=16
            :rules="keyRules"
            v-model="item.key"
          ></v-text-field>
        </td>
        <td
          class="hideInputMsg pt-1"
        >
          <v-text-field
            dense
            :readonly="!editMode"
            solo
            flat
            type="text"
            maxlength=1024
            :rules="valueRules"
            v-model="item.value"
          ></v-text-field>
        </td>
      </tr>
    </template>
    <template
      v-slot:[`body.append`]="{ headers }"
    >
      <tr
        class="trNoHighlight"
      >
        <td
          :colspan="headers.length"
        >
          <v-btn
            @click="addRow()"
            :disabled="!editMode"
            title="Añadir registro"
            small
            fab
            elevation="2"
            color="primary"
          >
            <v-icon>{{ mdiPlus }}</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mdiDelete, mdiPlus } from '@mdi/js'
import EventBus from '@/components/EventBus'
import Vue from 'vue'

export default {
  data () {
    return {
      mdiDelete: mdiDelete,
      mdiPlus: mdiPlus,
      valid: false,
      keyRules: [
        (v) => !!v || 'No puede estar en blanco',
        (v) => (v && v.length <= 16) || 'Longitud máxima 16 caracteres'
      ],
      valueRules: [
        (v) => !!v || 'No puede estar en blanco',
        (v) => (v && v.length <= 1024) || 'Longitud máxima 255 caracteres'
      ],

      headers: [
        {
          text: '',
          value: false,
          sortable: false,
          width: 100
        },
        {
          text: 'Key',
          value: 'key',
          width: 200
        },
        {
          text: 'Value',
          value: 'value'
        }
      ]
    }
  },
  props: {
    // value represents section passed as model
    value: Array,
    sectionId: [String],
    editMode: Boolean
  },
  computed: {
    sectionItems: {
      get () {
        console.log('get')
        return this.value
      },
      set (value) {
        console.log('set')
        this.$emit('input', value)
      }
    }
  },

  watch: {
    value: {
      handler: function (sectionItems) {
        console.log('handle')
        this.sectionItems = sectionItems
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }
  },
  mounted () {
    EventBus.$on('ctrlKey_Ins', this.addRow)
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_Ins', this.addRow)
  },
  methods: {
    addRow () {
      if (!this.editMode) {
        return
      }
      const newRow = {
        key: '',
        value: ''
      }
      this.sectionItems.push(newRow)
      // Focus the component, but we have to wait
      // so that it will be showing first.
      Vue.nextTick(() => {
        this.$refs[newRow.key].focus()
      })
    },
    deleteRow (key) {
      this.sectionItems = this.sectionItems.filter((el) => {
        return el.key !== key
      })
    }
  }
}
</script>
