<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="priceGroupLevels"
    :options="{itemsPerPage: -1}"
    class="elevation-0"
    mobile-breakpoint="0"
  >

    <template
      v-slot:item="{ item, index }"
    >
      <tr
        :key="index"
      >
        <td>
          <v-btn
            v-if="item.levelId === priceGroupLevels.length "
            :disabled="priceGroupLevels.length === 1 || !editMode"
            @click="deleteRow(item.levelId)"
            title="Borrar registro"
            x-small
            fab
            class="elevation-0"

          >
            <v-icon
              color="error"
            >
              {{ mdiDelete }}
            </v-icon>
          </v-btn>
        </td>
        <td>
          {{ item.levelId }}
        </td>

        <td
          class="hideInputMsg pt-1"
        >
          <v-text-field
            :ref="item.levelId"
            dense
            :readonly="!editMode"
            solo
            flat
            type="number"
            :rules="minQtyRules"
            v-model="item.minQty"
          ></v-text-field>
        </td>
        <td
          class="hideInputMsg pt-1"
        >
          <v-text-field
            dense
            :readonly="!editMode"
            solo
            flat
            type="number"
            :rules="maxQtyRules"
            v-model="item.maxQty"
          ></v-text-field>
        </td>
        <td
          class="hideInputMsg pt-1"
        >
          <v-text-field
            dense
            :readonly="!editMode"
            solo
            flat
            type="number"
            :rules="discountRules"
            v-model="item.discount"
          ></v-text-field>
        </td>
      </tr>
    </template>
    <template
      v-slot:[`body.append`]="{ headers }"
    >
      <tr
        class="trNoHighlight"
      >
        <td
          :colspan="headers.length"
        >
          <v-btn
            @click="addRow()"
            :disabled="!editMode"
            title="Añadir registro"
            small
            fab
            elevation="2"
            color="primary"
          >
            <v-icon>{{ mdiPlus }}</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mdiDelete, mdiPlus } from '@mdi/js'
import EventBus from '@/components/EventBus'
import Vue from 'vue'

export default {
  data () {
    return {
      mdiDelete: mdiDelete,
      mdiPlus: mdiPlus,
      valid: false,
      minQtyRules: [
        // (v) => !!v || 'No puede estar en blanco',
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Se requiere un número (integro)'
      ],
      maxQtyRules: [
        // (v) => !!v || 'No puede estar en blanco',
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Se requiere un número (integro)'
      ],

      discountRules: [
        // (v) => !!v || 'No puede estar en blanco',
        // positive decimal with up to 4 digits
        (v) => /^\d*\.{0,4}\d+$/.test(v) || 'Debe ser 0 o un número decimal positivo'
      ],
      headers: [
        {
          text: '',
          value: false,
          sortable: false,
          width: 100
        },
        {
          sortable: false,
          text: 'Id',
          value: 'levelId'
        },
        {
          sortable: false,
          text: 'Más de',
          value: 'minQty'
        },
        {
          sortable: false,
          text: 'Hasta',
          value: 'maxQty'
        },
        {
          sortable: false,
          text: 'Descuento',
          value: 'discount'
        }

      ]
    }
  },
  props: {
    // value represents section passed as model
    value: Array,
    priceGroupId: [String],
    editMode: Boolean
  },
  computed: {
    priceGroupLevels: {
      get () {
        console.log('get')
        return this.value
      },
      set (value) {
        console.log('set')
        this.$emit('input', value)
      }
    }
  },

  watch: {
    value: {
      handler: function (priceGroupLevels) {
        console.log('handle')
        this.priceGroupLevels = priceGroupLevels
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }
  },
  mounted () {
    EventBus.$on('ctrlKey_Ins', this.addRow)
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_Ins', this.addRow)
  },
  methods: {
    addRow () {
      if (!this.editMode) {
        return
      }
      const newRow = {
        priceGroupId: this.priceGroupId,
        levelId: this.priceGroupLevels.length + 1,
        minQty: this.priceGroupLevels[this.priceGroupLevels.length - 1]?.maxQty || 0,
        maxQty: 0,
        discount: 0
      }
      this.priceGroupLevels.push(newRow)
      // Focus the component, but we have to wait
      // so that it will be showing first.
      Vue.nextTick(() => {
        this.$refs[newRow.key].focus()
      })
    },
    deleteRow (levelId) {
      this.priceGroupLevels = this.priceGroupLevels.filter((el) => {
        return el.levelId !== levelId
      })
    }
  }
}
</script>
