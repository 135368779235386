<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >

            <v-row
            dense
            >
            <v-col
                v-if="!item.isNew"
            >
              <v-alert
                type="info"
                color="primary"
                text
                class="mb-0"
              >
                <div
                    v-if="item.productsUsingPriceGroup.length === 0"
                >
                    No hay productos que usan el priceGroup '<b>{{ item.priceGroupId }}</b>'.

                </div>
                <div
                    v-else
                    >
                    Los siguientes productos usan el priceGroup '<b>{{ item.priceGroupId }}</b>':
                    <br>
                    <span
                    v-for="product in item.productsUsingPriceGroup"
                    :key="product.productId"
                    >
                    {{ product.productId }},
                    </span>
                    <br>
                    <small
                      style="color:red;"
                    >
                      No se puede borrar este priceGroup mientras haya productos que lo usen.
                    </small>
                </div>
                </v-alert>
            </v-col>
            </v-row>

            <v-row
            dense
            class="pt-4"
            >
            <v-col
                cols="3"
                sm="2"
            >
                <v-text-field
                :disabled="!editMode || !item.isNew"
                :ref="item.isNew ? 'focussedInput' : ''"
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=12
                :rules="priceGroupIdRules"
                v-model="item.priceGroupId"
                label="priceGroupId"
                ></v-text-field>
            </v-col>
            <v-col
                cols="9"
                sm="4"
            >
                <v-text-field
                dense
                :readonly="!editMode"
                :ref="!item.isNew ? 'focussedInput' : ''"
                persistent-placeholder
                filled
                type="text"
                maxlength=50
                :rules="priceGroupNameRules"
                v-model="item.priceGroup.priceGroupName"
                label="priceGroupName"
                ></v-text-field>
            </v-col>
            <v-col
                cols="4"
                sm="2"
            >
                <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="number"
                :rules="extraDiscountLevelsRules"
                v-model="item.priceGroup.extraDiscountLevels"
                label="extraDiscountLevels"
                ></v-text-field>
            </v-col>
            <v-col
                cols="4"
                sm="2"
            >
                <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="number"
                :rules="maxDiscountRules"
                v-model="item.priceGroup.maxDiscount"
                label="maxDiscount"
                ></v-text-field>
            </v-col>
            <v-col
                cols="4"
                sm="2"
            >
                <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="number"
                :rules="commisionRules"
                v-model="item.priceGroup.commision"
                label="Comisión"
                ></v-text-field>
            </v-col>
            </v-row>

            <v-row
            dense
            >

            <v-col
                cols="12"
                sm="12"
            >
                <v-textarea
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=255
                v-model="item.priceGroup.priceGroupComments"
                rows="2"
                label="Comentarios"
                ></v-textarea>
            </v-col>
            </v-row
            >

            <v-row
            dense
            >
            <v-col
                mt
                cols="12"
                sm="12"
                class="my-4"
            >
                <SettingsPriceGroupLevelsTable
                v-model="item.priceGroupLevels"
                :priceGroupId="item.priceGroupId"
                :editMode="editMode"
                >
                </SettingsPriceGroupLevelsTable>
            </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions
        >
          <v-btn
            v-if="editMode && !item.isNew && userCanEdit && item.productsUsingPriceGroup.length === 0"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode && !item.isNew && userCanEdit"
            @click="copyItem"
            text
            title="Copiar y usar como modelo para nuevo elemento"
          >
            Copiar
          </v-btn>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
            title="Cancelar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode && userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
            title="Volver a lista (Esc)"
          >
            Volver
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import ItemMixins from '@/utils/ItemMixins'
import SettingsPriceGroupLevelsTable from '@/components/SettingsPriceGroupLevelsTable'

export default {
  mixins: [ItemMixins],
  components: {
    SettingsPriceGroupLevelsTable
  },
  data () {
    return {
      editRight: 'CAN_ADMIN',
      itemsName: 'priceGroups',
      itemName: 'priceGroup',
      item: {
        priceGroupId: 'new_', // redundant, used for crud operations
        priceGroup: {},
        productsUsingPriceGroup: [],
        priceGroupLevels: []
      },

      priceGroupIdRules: [
        (v) => !!v || 'No puede estar en blanco',
        (v) => (v && v.length <= 12) || 'Longitud máxima 12 caracteres'
      ],
      priceGroupNameRules: [
        (v) => !!v || 'No puede estar en blanco',
        (v) => (v && v.length <= 50) || 'Longitud máxima 50 caracteres'
      ],
      extraDiscountLevelsRules: [
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Se requiere un número (integro)'
      ],
      maxDiscountRules: [
        // positive decimal with up to 4 digits
        (v) => /^\d*\.{0,4}\d+$/.test(v) || 'Debe ser 0 o un número decimal positivo',
        (v) => +v < 1 || 'Tiene que ser inferior a 100%'
      ],
      commisionRules: [
        // positive decimal with up to 4 digits
        (v) => /^\d*\.{0,4}\d+$/.test(v) || 'Debe ser 0 o un número decimal positivo'
      ]
    }
  },
  mounted () {
    this.$on('saved', () => {
      console.log('on saved')
      this.$store.dispatch('initApp')
    })
    this.$on('removed', () => {
      console.log('on removed')
      this.$store.dispatch('initApp')
    })
  },
  methods: {
    copyItem () {
      this.createItem(this.item)
    },
    createItem (copyFrom) {
      if (copyFrom) {
        this.item = Object.assign({}, copyFrom)
        this.item.isNew = true
        this.item.priceGroupId = ''
        // replace path without rendering
        window.history.replaceState({}, null, '/#/' + this.itemsName + '/new_')
      } else {
        this.item = {
          // workarround for datasets where the id is userdefined
          isNew: true,
          priceGroupId: '',
          priceGroup: {
            priceGroupName: '',
            extraDiscountLevels: 0,
            maxDiscount: 0.2,
            commision: 0,
            priceGroupComments: ''
          },
          productsUsingPriceGroup: [],
          priceGroupLevels: [
            { levelId: 1, minQty: 0, maxQty: 1, discount: 0 },
            { levelId: 2, minQty: 1, maxQty: 2, discount: 0.1 },
            { levelId: 3, minQty: 2, maxQty: 0, discount: 0.2 }
          ]
        }
      }
      this.editMode = true
    }
  }
}
</script>
